import React from "react"
import { Container } from "reactstrap"

let SubFooter = ({ title }) => (
  <div className="bg-light">
    <Container className="pb-3">
      <span className="text-secondary small">
        Copyright © {new Date().getFullYear()}. {title}. All rights reserved. |
        made with ♥ by{" "}
        <a target="_blank" rel="noreferrer" href="https://www.fjoerg.com">
          fjoerg IT
        </a>
      </span>
    </Container>
  </div>
)

export default SubFooter
